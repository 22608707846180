import { Avatar, Chip, Grid } from "@material-ui/core"
import { graphql, Link, StaticQuery } from "gatsby"
import _ from "lodash"
import React from "react"
import { Twemoji } from "react-emoji-render"
import { chip, icon } from "./feature_tags.module.sass"

const FeatureTags = () => (
  <StaticQuery
    query={query}
    render={(data) => (
      <>
        <h2>おすすめのコンテンツ</h2>
        <Grid container spacing={1}>
          {data.allTagsYaml.edges.map((edge) => {
            return (
              <Grid item xs={12} sm={6}>
                <Link to={`/tags/${_.kebabCase(edge.node.name)}/`}>
                  <Chip
                    variant="outline"
                    label={edge.node.name}
                    className={chip}
                    clickable
                    avatar={
                      <Avatar
                        style={{
                          backgroundColor: edge ? edge.node.color : "#FFECB3",
                        }}
                      >
                        <Twemoji
                          svg
                          text={edge ? edge.node.emoji : "📝"}
                          className={icon}
                          onlyEmojiClassName=""
                        />
                      </Avatar>
                    }
                  />
                </Link>
              </Grid>
            )
          })}
        </Grid>
      </>
    )}
  />
)

const query = graphql`
  query {
    allTagsYaml(
      filter: { feature: { ne: null } }
      sort: { fields: [feature], order: DESC }
    ) {
      edges {
        node {
          name
          emoji
          color
        }
      }
    }
  }
`

export default FeatureTags
