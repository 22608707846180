import { graphql, StaticQuery } from "gatsby"
import React from "react"
import { Twemoji } from "react-emoji-render"
import {
  largeIcon,
  largeWrapper,
  mediumIcon,
  mediumWrapper,
  smallIcon,
  smallWrapper,
} from "./eye_catch.module.sass"

const EyeCatch = ({ tags, size }) => (
  <StaticQuery
    query={query}
    render={(data) => {
      const edge = data.allTagsYaml.edges.find((edge) =>
        tags.includes(edge.node.name)
      )
      return (
        <div
          className={
            size === "large"
              ? largeWrapper
              : size === "medium"
              ? mediumWrapper
              : smallWrapper
          }
          style={{ backgroundColor: edge ? edge.node.color : "#FFECB3" }}
        >
          <Twemoji
            svg
            text={edge ? edge.node.emoji : "📝"}
            className={
              size === "large"
                ? largeIcon
                : size === "medium"
                ? mediumIcon
                : smallIcon
            }
            onlyEmojiClassName=""
          />
        </div>
      )
    }}
  />
)

const query = graphql`
  query {
    allTagsYaml {
      edges {
        node {
          name
          emoji
          color
        }
      }
    }
  }
`

export default EyeCatch
