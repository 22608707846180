import { Chip } from "@material-ui/core"
import { Link } from "gatsby"
import _ from "lodash"
import React from "react"
import { activeTag, tag } from "./tag_link.module.sass"

const TagLink = ({ count, active, children }) => (
  <Link to={`/tags/${_.kebabCase(children)}/`}>
    <Chip
      className={active ? activeTag : tag}
      label={`#${children}`}
      variant="outlined"
      size="small"
      clickable
    />
  </Link>
)

export default TagLink
