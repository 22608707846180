import { ScheduleRounded } from "@material-ui/icons"
import React from "react"
import { dateStyle, icon } from "./date.module.sass"

const Date = ({ date }) => (
  <p className={`iconText ${dateStyle}`}>
    <ScheduleRounded fontSize="inherit" className={icon} />
    {date}
  </p>
)

export default Date
